<template>
    <el-dialog title="新增费用" :visible.sync="dialogFormVisible" width="500px">
        <div>
            <el-form :model="form" :label-width="'100px'">
                <el-form-item label="费用名称" >
                    <el-select
                        style="width:100%"
                        size="small"
                        v-model="cost_name"
                        filterable
                        remote
                        placeholder="请输入费用名称"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option
                            v-for="(item,i) in options"
                            :key="item.id"
                            :label="item.name_zh"
                            :value="i">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单位" >
                    <el-radio v-model="form.measure_unit" :label="6">票</el-radio>
                    <el-radio v-model="form.measure_unit" :label="5">箱</el-radio>
                </el-form-item>
                <el-form-item label="币种">
                    <el-radio v-model="form.currency" label="CNY">人民币</el-radio>
                    <el-radio v-model="form.currency" label="USD">美元</el-radio>
                    <el-radio v-model="form.currency" v-if="form.currency&&form.currency!='CNY'&&form.currency!='USD'" :label="form.currency">{{form.currency}}</el-radio>
                </el-form-item>
                <el-form-item label="单价"  size="small" v-if="form.measure_unit==6">
                    <el-input v-model="form.price" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="20GP"  size="small" v-if="form.measure_unit==5">
                    <el-input v-model="form.price_20gp" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="40GP"  size="small" v-if="form.measure_unit==5">
                    <el-input v-model="form.price_40gp" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="40HQ"  size="small" v-if="form.measure_unit==5">
                    <el-input v-model="form.price_40hc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="快捷添加" >
                    <div class="tag_box">
                        <div  v-for="item in quiceArr" :key="item.id">
                            <el-tag
                                @click="tj_item_click(item,1)"
                                class="cursor myTag"
                                effect="plain">
                                {{ item.name_zh }}
                            </el-tag>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false" size='small'>取 消</el-button>
            <el-button type="primary" @click="handle_add" size='small'>确 定</el-button>
        </div>
</el-dialog>
</template>
<script>
export default {
    data() {
        return{
            prop_index:null,
            dialogFormVisible: false,
            cost_name:"",
            form: {
                measure_unit:6,
                currency:"",
                price:""
            },
            quiceArr:[],
            options:[],
            loading:false
        }
    },
    watch:{
        cost_name(value){
            if(value!=null){
                this.tj_item_click(this.options[value])
            }
        }
    },
    mounted(){
        this.getQuickData()
    },
    methods:{
        async getQuickData(){
           let res=await this.$store.dispatch('baseConsole/get_fee',{is_quick_show:1});
            this.quiceArr=res.data
        },
        openDialog(index){
            this.prop_index=index
            this.cost_name=null
            this.form={
                measure_unit:6,
                currency:""
            },
            this.options=[],
            this.dialogFormVisible=true
        },
        handle_add(){
            console.log(this.form);
            if(!this.form.name_zh&&!this.form.name_en){
                this.$message.warning('请选择费用项')
                return
            }
            let itemObj=this.form
            let obj=null
            if(itemObj.measure_unit==6){ // 票
                itemObj.price_20gp=""
                itemObj.price_40gp=""
                itemObj.price_40hc=""
                if(!itemObj.price){
                    this.$message.warning('请输入单价')
                    return
                }
            }else{ // 箱
                itemObj.price=""
                console.log('itemObjitemObj---',itemObj);
                console.log(itemObj.price_20gp*1 , itemObj.price_40gp*1 , itemObj.price_40hc*1);
                if(!itemObj.price_20gp*1 || !itemObj.price_40gp*1 || !itemObj.price_40hc*1){
                    this.$message.warning('价格都不能为空')
                    return
                }
            }
            obj={
                GP20: itemObj.price_20gp,
                GP40: itemObj.price_40gp,
                HC40: itemObj.price_40hc,
                code: itemObj.currency,
                decimal_digits: 2,
                fee_id: itemObj.id,
                lowest_price:itemObj.lowest_price,
                measure: itemObj.fee_unit_code,  // ee.measure != '箱' && ee.measure != 'GP'
                name_en:itemObj.name_en,
                name_zh:itemObj.name_zh,
                price:itemObj.price,
                remarks:itemObj.remarks||"",
                total_price:"",
                unit:itemObj.measure_unit
            }
            console.log(obj);
            if(obj.unit==5){ 
                obj.measure = 'GP'
            
            }
            this.$emit('ret_cost',{index:this.prop_index,obj})
            this.dialogFormVisible=false
        },
        async remoteMethod(query) {
            console.log(this.form);
            if (query !== '') {
                this.loading = true;
                console.log(query);
                let res=await this.$store.dispatch('baseConsole/get_fee',{
                    name:query,
                    nopage:1,
                });
                console.log(res);
                this.options=res.data
                this.loading = false
            } else {
                this.options = [];
            }
        },
        // 快捷添加点击事件  && 搜索项的点击事件
        tj_item_click(itemObj,type=0){
            if(type==1){
                this.options=[]
                this.options.push(itemObj)
                this.cost_name=0
            }
            console.log('-------',itemObj);
            itemObj.price=itemObj.unit_price*1
            itemObj.price_20gp=itemObj.price_20gp
            itemObj.price_40gp=itemObj.price_40gp
            itemObj.price_40hc=itemObj.price_40hc
            if(itemObj.fee_unit_code!= '箱' && itemObj.fee_unit_code != 'GP'){
                itemObj.measure_unit =6
            }else{
                itemObj.measure_unit =5
            }
            this.form=JSON.parse(JSON.stringify(itemObj))
            console.log('this.form',this.form);
        },
    }
}
</script>
<style lang="less" scoped>
    .tag_box{
        display: flex;
        flex-wrap: wrap;
    }
    .myTag{
        margin-right:15px;
        margin-bottom:15px ;
    }
    .cost_name{
        height: 40px;
        width: 100;
        border-bottom: 1px solid #DCDFE6;
        cursor: pointer;
    }
    /deep/ .el-dialog__body{
        padding-bottom: 0;
    }
</style>