<template>
    <div class="s_box">
        <pageTitle title="整箱运价详情">
            <template slot="right">
                <el-button type="primary" size="small" style="background:#91BE42;border-color:#91BE42" @click="create_inquire">完成报价</el-button>
            </template>
        </pageTitle>
        <shipMsg :header='header' pageType='share'  v-loading="loading" ></shipMsg>
        <div class="cost_detail">
          <div v-for="(item,i) in  header.container" :key='i' v-if="item.reject==0 && item.number&&item.price!=0">

            <h1>{{item.name+'成本价'}}</h1>
            <div v-if="item.price">
              <span class="code">{{ item.code }}</span>
              <span class="num">{{ item.sell }}</span>
            </div>
            <div v-else class="">-</div>
          </div>
        </div>
        <div class="bottom_box">
          <div class="left">
              <pageTitle title="费用明细（报价时以下列的费用为准）"></pageTitle>
              <!-- <div class="sea_cost">
                <span>请选择箱型</span>
                <el-checkbox-group v-model="checkList_sea" @change='checkList_sea_change'>
                  <el-checkbox label="20GP"></el-checkbox>
                  <el-checkbox label="40GP"></el-checkbox>
                  <el-checkbox label="45HQ"></el-checkbox>
                </el-checkbox-group>
                <span>至少需选择一种箱型，将以选择的箱型进行报价</span>
              </div> -->

              <!-- <div class="unnoMsg" v-if="header.un_no">
                <div class="no">
                  <span class="no_c">UNNO: </span>
                  <el-input style="width:120px" class="no_c" v-model="un_no" size="small" ></el-input>
                  <el-button class="no_c" type="warning" @click="check_unno" size='small'>重新计算</el-button>
                  <span class="no_remark">更改UNNO需重新调整海运费</span>
                </div>
                <div class="psn">PSN:{{ header.pscn | textFormat }}</div>
                <div class="class">
                    Class:{{ header.classify | textFormat }}
                </div>
                <div class="PG">PG:{{ header.packing_group | textFormat }}</div>
              </div> -->
              <!-- 被动报价不能修改危险品信息 和箱型个数 -->

              <div class="unnoMsg" v-if="header.is_dangerous">
                <div >UNNO：  <span class="color">{{ header.un_no | textFormat }}</span></div>
                <div >PSN： {{ header.pscn | textFormat }}</div>
                <div >Class： {{ header.classify | textFormat }}</div>
                <div >PG： {{ header.packing_group | textFormat }}</div>
              </div>
          <div class="moneyList" v-loading="loading">
            <div class="seaItem">
              <div class="itemTitleBox">
                <div>
                   <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                  <span class="itemTitle">海运费：</span>
                  <span class="itemMOney">
                    {{ header.currency }}
                    <span class='price'>{{ parseInt(seaTotal) }}</span>
                    </span><span class="itemTips"></span>
                </div>
              </div>
              <div class="itemContent">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4">单价</div>
                </div>
                <div
                  class="tableLine border"
                  v-for="(item, index) in header.container"
                  :key="index"
                >
                  <div  v-if="item.number&&item.reject==0&&item.price!=0" style="display: flex;">
                    <div class="table1" style="display:flex;align-items: center;">海运费</div>
                    <div class="table2" style="display:flex;align-items: center;">{{ item.code||"USD" }}</div>
                    <div class="table3" style="display:flex;align-items: center;">{{ item.name }}</div>
                    <div
                      style="display:flex;align-items: center;"
                      class="table4"
                      :class="!header.is_open && !header.is_quote ? 'mohu' : 'price'"
                    >
                      {{ item.price }}
                      <i class="el-icon-edit cursor" @click="handle_cost_item(item,index)"></i>
                      <div v-if="item.is_edit" style="display:flex;align-items: center;padding-left:10px">
                        <el-input
                          style="width:180px;display:flex;align-items: center;"
                          placeholder="请输溢价金额（整数）"
                          type='number'
                          size="mini"
                          v-model="header.container[index]._price"
                          clearable>
                        </el-input>
                        <el-button style="margin-left:10px" @click="handle_cost_sure(item,index)"  type="primary" size="mini">确定</el-button>
                        <el-button size="mini"  @click="handle_cost_cancle(item,index)" >取消</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <costComp
              :costList='costList'
              :edit='true'
              @open_close="open_close"
              @cost_item_del="cost_item_del"
              @addCost="addCost"
            ></costComp>
          </div>
          </div>
        </div>
        <div class="page_tips">
          <i class="el-icon-warning"></i>
          <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
        </div>
        <costAdd ref="c_costAdd" @ret_cost="ret_cost" ></costAdd>
    </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import costAdd from "~bac/components/ymp/costAdd";
import shipMsg from "~bac/components/ymp/shipMsg";
import costComp from "~bac/components/ymp/costComp";
export default {
    data() {
        return{
            type:0, //
            seaTotal:0,
            header:{},
            remarkArr:[],
            costList:[],
            myContainer:[],
            loading:false,
            textarea:"",
            checkList_sea:['20GP'],
            un_no:"",
        }
    },
    mounted(){
      this.type=this.$route.query.type
      console.log(this.$route,this.type);
      // type==1  客户询价--我要报价（被动报价） 不再兼容主动报价
        this.getHeader()

    },
    components:{
        pageTitle,
        costAdd,
        shipMsg,
        costComp
    },
    filters:{
      my_Fixed(value){
        let num=0
        num=Number(value).toFixed()
        return num
      }
    },
    methods:{
        changeShow(item){
            item.open = !item.open
            this.$forceUpdate()
        },
        add_cost(index){
           this.$refs.c_costAdd.openDialog(index)
        },
        async create_inquire(){
          this.$log({
            type: 1,
            topic: "ymp.workbench",
            id: "ymp.workbench.inquire.offerSave",
            content: JSON.stringify({
              id: this.$route.query.id || ''
            }),
          });
          if(true){ // 客户询价  被动报价 this.type==1 不在兼容主动报价
            let container = this.header.container
            container.map(item=>{
              delete item.is_edit
              delete item._price
              return item
            })
            let params={
              inquiry_id:this.$route.query.id,
              sales_id:this.USER_INFO.id,//报价销售ID,
              inquiry_type:2,//1-整箱，2-拼箱
              freight_id:this.header.id,//运价id
              is_dangerous:this.header.is_dangerous,//是否危险品：0-否，1-是
              un_no:this.header.un_no,//危险品编号
              classify:this.header.classify,//危险品类别,
              surcharge:JSON.stringify(this.costList),//扩展信息；json类型,
              sea_freight:JSON.stringify(container),//箱体信息；json类型,
              is_quote:1,//是否已报价：0-否，1-是（销售主动报价传 1）
            }
            let res=await this.$store.dispatch("baseConsole/res_to_inquiry_save",params)
            console.log(res);
            this.$router.push({
              query: {
                  inquiry_id: this.$route.query.id,
              },
              path: "/console/myoffer/customer_inquiry_detail",
            })

          }else{
            // let container = this.header.container
            // container.map(item=>{
            //   delete item.is_edit
            //   delete item._price
            //   return item
            // })
            // let obj={
            //   user_id:this.USER_INFO.id,
            //   inquiry_type:2,//1-整箱，2-拼箱
            //   freight_id:this.header.freight_id,   //运价id
            //   cargo_name:"",//货物名称
            //   is_dangerous:this.unnoFalg?1:0,//是否危险品：0-否，1-是
            //   un_no:this.header.un_no,//危险品编号
            //   classify:this.header.classify||null,//危险品类别,
            //   surcharge:JSON.stringify(this.costList),//扩展信息；json类型,
            //   sea_freight:JSON.stringify(container),//箱体信息；json类型,
            //   is_quote:1,//是否已报价：0-否，1-是（销售主动报价传 1）
            //   sales_id:this.USER_INFO.id,//报价销售ID,,
            // }
            // let res=await this.$store.dispatch('baseConcle/offer_save',obj)
            // console.log('rrrrr',res);

            // this.$router.push({
            //   query: {
            //       id: this.$route.query.id,
            //   },
            //   path: "/console/myoffer/detail",
            // })
          }
        },
        // 子组件 --删除费用
        cost_item_del(e){
          // this.computed_price_del(e.index,e.obj)
          this.costList[e.index].data_list.splice(e.i,1)
          this.effect_cost()
        },
        // 子组件 --添加费用
        addCost(e){
          this.$refs.c_costAdd.openDialog(e.index)
        },
        // 海运费 重新计算
        async check_unno(){
          let res=await this.$store.dispatch('baseConsole/check_unno',{unno:this.un_no})
          if(res.success){
            let params={
              user_type: 2,
              freight_id:this.$route.query.id,
              unno:this.un_no ,
              dangerous_status: 1
            }
            let res=await this.$store.dispatch("baseConsole/new_freight_detail",params)
            this.header.un_no=this.un_no
            console.log('yyyy',res);

            this.header.pscn=res.data.pscn,
            this.header.classify=res.data.classify,
            this.header.packing_group=res.data.packing_group

            this.header.container[0].price=res.data.price_20gp
            this.header.container[1].price=res.data.price_40gp
            this.header.container[2].price=res.data.price_40hc
            
            

            // this.header.price_20gp_of_sell=res.data.price_20gp_of_sell,
            // this.header.price_40gp=res.data.price_40gp,
            // this.header.price_40gp_of_sell=res.data.price_40gp_of_sell,
            // this.header.price_40hc=res.data.price_40hc,
            // this.header.price_40hc_of_sell=res.data.price_40hc_of_sell


          }else{
            this.un_no=this.header.un_no
            this.$message.warning('您输入的UNNO有误，UNNO和海运费不变')
          }
          console.log(res);
        },
        // 选择箱型
        checkList_sea_change(e){
          console.log(this.checkList_sea);
          console.log(this.header.container);
          this.header.container.map(item=>{
            if(this.checkList_sea.includes(item.name)){
              item.number=1
            }else{
              item.number=0
            }
            return item
          })
        },
        // 子组件  -打开关闭
        open_close(e){
          this.costList[e.key].open=!this.costList[e.key].open
          this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        ret_cost(e){ // 添加费用 的 事件函数
          console.log(' 添加费用 的 事件函数',e);
          this.costList[e.index].data_list.push(e.obj)
          // this.computed_price_add(e.index,e.obj)
          this.effect_cost()
        },
        // 计算附加费的价格--添加
        computed_price_add(index,obj){
          let totalPrice = this.costList[index].group_total_price
          if(obj.unit == 5){ // 箱
            let checkedArr=[]
            for(var h of this.header.container){
              if(h.number&&h.reject==0&&h.price){
                checkedArr.push(h.name)
              }
            }
            for(var key of checkedArr){
              console.log('key--kkk',key);
              if(key=='20GP'){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.GP20)
                  }else{
                    totalPrice[obj.code]=Number(obj.GP20)
                  }
              }
              if(key=='40GP'){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.GP40)
                  }else{
                    totalPrice[obj.code]=Number(obj.GP40)
                  }
              }
              if(key=="40HQ"){
                  if(totalPrice[obj.code]){
                    totalPrice[obj.code]+=Number(obj.HC40)
                  }else{
                    totalPrice[obj.code]=Number(obj.HC40)
                  }
              }
            }
          }else{             // 票 ok
            if(totalPrice[obj.code]){
              totalPrice[obj.code]+=Number(obj.price)
            }else{
              totalPrice[obj.code]=Number(obj.price)
            }
          }
          this.costList[index].group_total_price = totalPrice
          this.costList=JSON.parse(JSON.stringify(this.costList))
          this.$forceUpdate();
        },
        // // 计算附加费的价格--删除
        computed_price_del(index,obj){
          let totalPrice = this.costList[index].group_total_price
          if(obj.unit == 5||obj.measure=="GP"||obj.measure=="箱"){ // 箱
          let checkedArr=[]
            for(var h of this.header.container){
              if(h.number&&h.reject==0&&h.price){
                checkedArr.push(h.name)
              }
            }
            for(var key of checkedArr){
              console.log('key--kkk',key);
              if(key=='20GP'){
                totalPrice[obj.code]-=Number(obj.GP20)
              }
              if(key=='40GP'){
                  totalPrice[obj.code]-=Number(obj.GP40)
              }
              if(key=="40HQ"){
                  totalPrice[obj.code]-=Number(obj.HC40)
              }
            }
          }else{             // 票 ok
            totalPrice[obj.code]-=Number(obj.price)
          }
          this.costList[index].group_total_price = totalPrice
          this.$forceUpdate();
        },
        //  费用项目 edit
        handle_cost_item(item,i){
          this.header.container[i].is_edit = !this.header.container[i].is_edit
          this.header.container[i]._price =null
        },
        // 选择箱型===>影响附加费  header.container
        effect_cost(){

          let arr=[]
            for( var dd of this.header.container ){
              if(dd.price!=0&&dd.number==1){
                arr.push(dd.name)
              }
            }
            console.log('aaaaaaa--CCCthis.costList',this.costList);
            console.log('bbbbbbbbb',this.header.container);
            for(var item of this.costList){
              item.group_total_price={}
              for(var it of item.data_list){

                if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱
                  for(var key of arr){
                    if(key=='20GP'){
                      if(item.group_total_price[it.code]){
                        item.group_total_price[it.code]+=Number(it.GP20)
                      }else{
                        item.group_total_price[it.code]=Number(it.GP20)
                      }
                    }
                    if(key=='40GP'){
                      if(item.group_total_price[it.code]){
                        item.group_total_price[it.code]+=Number(it.GP40)
                      }else{
                        item.group_total_price[it.code]=Number(it.GP40)
                      }
                    }
                    if(key=="40HQ"){
                        if(item.group_total_price[it.code]){
                          item.group_total_price[it.code]+=Number(it.HC40)
                        }else{
                          item.group_total_price[it.code]=Number(it.HC40)
                        }
                    }
                  }
                }else if(it.code==""){  // 没有币种得不计算

                }else{  // 票
                  if(item.group_total_price[it.code]){
                    item.group_total_price[it.code]+=Number(it.price)
                  }else{
                    item.group_total_price[it.code]=Number(it.price)
                  }
                }
              }
            }
            this.costList=JSON.parse(JSON.stringify(this.costList))
        },
        //  费用项目 确定
        handle_cost_sure(item,i){
          // if(this.header.container[i]._price==0){
          //   this.$message.warning("价格不可调整为0")
          //   return
          // }

          // this.header.container[i].price = parseInt(this.header.container[i]._price)
          // this.header.container[i].is_edit = !this.header.container[i].is_edit
          // this.computed_sea()
          if(this.header.container[i]._price!=0 && this.header.container[i]._price){
            console.log('cccc',this.header.container[i]._price);
            this.header.container[i].price =Number(this.header.container[i]._price*1+this.header.container[i].sell*1).toFixed()
            this.computed_sea()
            this.effect_cost()
            this.header.container[i].is_edit = !this.header.container[i].is_edit
          }else{
            this.$message.warning("修改溢价时，溢价金额不能为0")
          }
          

        },
        //  费用项目 取消
        handle_cost_cancle(item,i){
          this.header.container[i].is_edit = !this.header.container[i].is_edit
          this.header.container[i]._price = this.header.container[i].price
        },
        async handleRemark(){
            if(!this.textarea.length){
                return
            }
            let params={
                quotation_id: this.$route.query.id,
                remarks:this.textarea
            }
            await this.$store.dispatch("baseConsole/addBaseInfo",params)
            await this.getHeader()
            this.textarea=""
        },
        // 计算海运费
        computed_sea(){
          let money = 0;
          this.header.container.forEach((ee) => {
            if (ee.number) {
              money = money + ee.price*1;
            }
          });
          this.seaTotal = money;
        },
        async getHeader() {
          this.loading = true;
          let params = {
            inquiry_id: this.$route.query.id,
            user_type: 2
          };
          let result = await Promise.all([
            this.$store.dispatch("baseConsole/particulars", params),
          ]);
          this.loading = false;
          if (result[0].success) {
            let data = result[0].data;

            let now = this.$moment().unix()
            if (data.is_quote == 0 && data.validity_end < now) {
              data.is_quote = 2
            }
            this.un_no=data.un_no
            data.week = [];
            data.abs = Math.abs(data.price_lcl_dangerous);
            data.shipping_date_arr = data.shipping_date.split(",");
            data.shipping_date_arr.forEach((ee) => {
              if (ee == 1) {
                data.week.push("周一");
              }
              if (ee == 2) {
                data.week.push("周二");
              }
              if (ee == 3) {
                data.week.push("周三");
              }
              if (ee == 4) {
                data.week.push("周四");
              }
              if (ee == 5) {
                data.week.push("周五");
              }
              if (ee == 6) {
                data.week.push("周六");
              }
              if (ee == 7) {
                data.week.push("周日");
              }
            });
            data.container = JSON.parse(data.container);
            
            let money = 0;

            // container

            data.container.forEach((ee) => {
              ee.is_edit=false
              ee._price=ee.price
              ee.sell=Number(ee.sell).toFixed()
              if (ee.number) {
                money = money + ee.price*1;
              }
            });
            
            if(data.container.length){
              data.container[0].reject=data.reject_20gp
              data.container[1].reject=data.reject_40gp
              data.container[2].reject=data.reject_40hc
              // data.container[3].reject=data.reject_45gp
            }


            try {
              this.costList = JSON.parse(data.extend);
            } catch (error) {
              this.costList = []
            }
            if(this.costList){
              this.costList.forEach((ee, index) => {
                ee.canCopy = true;
                ee.open = true;
                if (index > 0) {
                  ee.open = false;
                }
              });
            }
            console.log(' this.costList', this.costList);

            this.seaTotal = money;
            this.header = data;
            this.effect_cost()
          }
        },
    }
}
</script>
<style scoped lang='less'>
.border{
  border-bottom: 1px solid #EBEEF5
}
.sea_cost{
  display: flex;
  padding-left: 30px;
  margin-bottom: 30px;
  span:first-child{
    padding-right: 30px;
  }
  span:nth-child(3){
    padding-left: 30px;
  }
}
.bottom_box{
    border-top: 10px solid #f2f2f2;
    display: flex;
    .title{
        border-bottom:1px solid #f2f2f2;
        padding-bottom: 15px;
    }
    .left{
        border-right:10px solid #f2f2f2;
        width: 100%;
        padding-right: 20px;
    }
    // .right{
    //     padding:15px;
    //     width: 30%;
    //     padding-left: 20px;
    //     .tips{
    //         padding-bottom: 10px;
    //     }
    //     .r_list_item{
    //         border-bottom: 1px solid #f2f2f2;
    //         padding-bottom: 10px;
    //         .msg{
    //             padding: 10px 0;
    //             word-wrap: break-word;
    //             word-break: normal;
    //         }
    //         .tt{
    //             text-align: right
    //         }
    //     }
    // }
}
.cost_detail{
  border-top:10px solid #f2f2f2;
  height: 118px;
  padding: 19px 0;
  display: flex;
  // justify-content: space-around;
  >div{
    width: 25%;
    border-left:1px solid #DCDFE6;
    text-align: center;
    h1{
      padding-bottom: 10px;
      height: 33px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 33px;
    }
    .code,.num{
      font-size: 18px;
      color: #FF7727;
    }
    .num{
      display: inline-block;
      padding-left: 5px;
      font-size: 24px;
    }
  }
  :first-child{
    border-left:0;
  }
}
.header {
    background: #fff;
    margin-top: 16px;
    padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;
    .div1 {
      max-width: 250px;
    }
    .div2 {
      max-width: 250px;
      margin: 0 20px;
    }
    .div3 {
      max-width: 250px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
    }
    .portZh {
      margin-top: 6px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    padding-left: 50px;
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .div1 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div2 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div3 {
      width: 200px;
      color: rgba(0, 0, 0, 0.65);
    }
    .div4 {
      width: 300px;
      color: rgba(0, 0, 0, 0.65);
      overflow: visible;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
}
.unnoMsg {
  display: flex;
  align-items: center;
  padding-left:20px;
  div{
    margin-right: 40px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3C5058;
    line-height: 20px;
  }
  .color{
    color: #91BE42;
  }
}
.moneyList {
  background: #fff;
  padding: 20px;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;

    }
    .tableLine>div{
      padding: 13px 0;
      min-height: 60px;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        text-align: center;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.nodata{
  height: 200px;
  text-align: center;
  line-height: 200px;
  color: #606266;
}
.page_tips{
  padding:26px 0;
  background: #F2f2f2;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.p_remarks{
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 14px;
}

</style>
